<template>
  <esmp-input disabled :label="label" />
</template>

<script>
export default {
  name: 'InputView',
  props: {
    settings: {
      type: Array,
      default: () => [],
    },
  },
  computed: {
    label() {
      return this.settings.find((el) => el.techName === 'name').value;
    },
  },
};
</script>
